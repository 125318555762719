<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <template>
          <div>
            <h3 class="mb-4">Reviews</h3>
            <div class="search-box-area">
              <div class="form-group position-relative">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search here"
                />
                <i class="fas fa-search"></i>
              </div>
              <div class="publish-del-section">
                <div class="form-group">
                  <button class="success-btn btnn">
                    <i class="far fa-check-circle"></i> Publish
                  </button>
                  <button class="delete-btn btnn ml-3">
                    <i class="far fa-times-circle"></i> Delete
                  </button>
                  <div class="position-relative calendar-dropdown ml-3">
                    <i class="fas fa-calendar text-orange"></i> Filter
                    <i class="fas fa-angle-down text-orange"></i>
                  </div>
                  <button class="refresh-btn ml-3">
                    <i class="fas fa-sync-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="reviews-section-wrapper">
              <div class="table-responsive reviews-wrapper">
                <table>
                  <tr>
                    <td class="checkbox-sec-width">
                      <b-form-checkbox size="lg"></b-form-checkbox>
                    </td>
                    <td class="img-sec-width">
                      <img :src="'loyal-img.png'" />
                    </td>
                    <td class="heading-sec-width">
                      <span class="text-orange">#C01234</span>
                      <p class="font-weight-bold mb-0">Margaretha</p>
                      <span class="text-grey">26/04/2020, 12:42 AM</span>
                    </td>
                    <td class="detail-sec-width">
                      <p>
                        We recently had dinner with friends at David CC and we
                        all walked away with a great experience. Good food,
                        pleasant environment, personal attention through all the
                        evening. Thanks to the team and we will be back!
                      </p>
                    </td>
                    <td class="rating-sec-width text-center">
                      <h3 class="font-weight-bold">4.5</h3>
                      <span><i class="fas fa-star text-yellow"></i></span>
                      <span><i class="fas fa-star text-yellow"></i></span>
                      <span><i class="fas fa-star text-yellow"></i></span>
                      <span><i class="fas fa-star text-yellow"></i></span>
                      <span><i class="fas fa-star"></i></span>
                    </td>
                    <td class="action-sec-width">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs"
                        version="1.1"
                        width=""
                        height=""
                        x="0"
                        y="0"
                        viewBox="0 0 52 52"
                        style="enable-background: new 0 0 512 512;"
                        xml:space="preserve"
                        class=""
                      >
                        <g>
                          <g xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26   S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"
                              fill="#56c662"
                              data-original="#000000"
                              style=""
                              class=""
                            />
                            <path
                              d="M38.252,15.336l-15.369,17.29l-9.259-7.407c-0.43-0.345-1.061-0.274-1.405,0.156c-0.345,0.432-0.275,1.061,0.156,1.406   l10,8C22.559,34.928,22.78,35,23,35c0.276,0,0.551-0.114,0.748-0.336l16-18c0.367-0.412,0.33-1.045-0.083-1.411   C39.251,14.885,38.62,14.922,38.252,15.336z"
                              fill="#56c662"
                              data-original="#000000"
                              style=""
                              class=""
                            />
                          </g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                        </g>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs"
                        version="1.1"
                        width=""
                        height=""
                        x="0"
                        y="0"
                        viewBox="0 0 512 512"
                        style="enable-background: new 0 0 512 512;"
                        xml:space="preserve"
                        class="ml-3"
                      >
                        <g>
                          <g xmlns="http://www.w3.org/2000/svg">
                            <g>
                              <path
                                d="M256,0C114.508,0,0,114.497,0,256c0,141.493,114.497,256,256,256c141.492,0,256-114.497,256-256    C512,114.507,397.503,0,256,0z M256,472c-119.384,0-216-96.607-216-216c0-119.385,96.607-216,216-216    c119.384,0,216,96.607,216,216C472,375.385,375.393,472,256,472z"
                                fill="#ff0000"
                                data-original="#000000"
                                style=""
                                class=""
                              />
                            </g>
                          </g>
                          <g xmlns="http://www.w3.org/2000/svg">
                            <g>
                              <path
                                :d="x"
                                fill="#ff0000"
                                data-original="#000000"
                                style=""
                                class=""
                              />
                            </g>
                          </g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                        </g>
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td class="checkbox-sec-width">
                      <b-form-checkbox size="lg"></b-form-checkbox>
                    </td>
                    <td class="img-sec-width">
                      <img :src="'loyal-img.png'" />
                    </td>
                    <td class="heading-sec-width">
                      <span class="text-orange">#C01234</span>
                      <p class="font-weight-bold mb-0">Margaretha</p>
                      <span class="text-grey">26/04/2020, 12:42 AM</span>
                    </td>
                    <td class="detail-sec-width">
                      <p>
                        We recently had dinner with friends at David CC and we
                        all walked away with a great experience. Good food,
                        pleasant environment, personal attention through all the
                        evening. Thanks to the team and we will be back!
                      </p>
                    </td>
                    <td class="rating-sec-width text-center">
                      <h3 class="font-weight-bold">4.5</h3>
                      <span><i class="fas fa-star text-yellow"></i></span>
                      <span><i class="fas fa-star text-yellow"></i></span>
                      <span><i class="fas fa-star text-yellow"></i></span>
                      <span><i class="fas fa-star text-yellow"></i></span>
                      <span><i class="fas fa-star"></i></span>
                    </td>
                    <td class="action-sec-width">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs"
                        version="1.1"
                        width=""
                        height=""
                        x="0"
                        y="0"
                        viewBox="0 0 52 52"
                        style="enable-background: new 0 0 512 512;"
                        xml:space="preserve"
                        class=""
                      >
                        <g>
                          <g xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26   S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"
                              fill="#56c662"
                              data-original="#000000"
                              style=""
                              class=""
                            />
                            <path
                              d="M38.252,15.336l-15.369,17.29l-9.259-7.407c-0.43-0.345-1.061-0.274-1.405,0.156c-0.345,0.432-0.275,1.061,0.156,1.406   l10,8C22.559,34.928,22.78,35,23,35c0.276,0,0.551-0.114,0.748-0.336l16-18c0.367-0.412,0.33-1.045-0.083-1.411   C39.251,14.885,38.62,14.922,38.252,15.336z"
                              fill="#56c662"
                              data-original="#000000"
                              style=""
                              class=""
                            />
                          </g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                        </g>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs"
                        version="1.1"
                        width=""
                        height=""
                        x="0"
                        y="0"
                        viewBox="0 0 512 512"
                        style="enable-background: new 0 0 512 512;"
                        xml:space="preserve"
                        class="ml-3"
                      >
                        <g>
                          <g xmlns="http://www.w3.org/2000/svg">
                            <g>
                              <path
                                d="M256,0C114.508,0,0,114.497,0,256c0,141.493,114.497,256,256,256c141.492,0,256-114.497,256-256    C512,114.507,397.503,0,256,0z M256,472c-119.384,0-216-96.607-216-216c0-119.385,96.607-216,216-216    c119.384,0,216,96.607,216,216C472,375.385,375.393,472,256,472z"
                                fill="#ff0000"
                                data-original="#000000"
                                style=""
                                class=""
                              />
                            </g>
                          </g>
                          <g xmlns="http://www.w3.org/2000/svg">
                            <g>
                              <path
                                :d="x"
                                fill="#ff0000"
                                data-original="#000000"
                                style=""
                                class=""
                              />
                            </g>
                          </g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                        </g>
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td class="checkbox-sec-width">
                      <b-form-checkbox size="lg"></b-form-checkbox>
                    </td>
                    <td class="img-sec-width">
                      <img :src="'loyal-img.png'" />
                    </td>
                    <td class="heading-sec-width">
                      <span class="text-orange">#C01234</span>
                      <p class="font-weight-bold mb-0">Margaretha</p>
                      <span class="text-grey">26/04/2020, 12:42 AM</span>
                    </td>
                    <td class="detail-sec-width">
                      <p>
                        We recently had dinner with friends at David CC and we
                        all walked away with a great experience. Good food,
                        pleasant environment, personal attention through all the
                        evening. Thanks to the team and we will be back!
                      </p>
                    </td>
                    <td class="rating-sec-width text-center">
                      <h3 class="font-weight-bold">4.5</h3>
                      <span><i class="fas fa-star text-yellow"></i></span>
                      <span><i class="fas fa-star text-yellow"></i></span>
                      <span><i class="fas fa-star text-yellow"></i></span>
                      <span><i class="fas fa-star text-yellow"></i></span>
                      <span><i class="fas fa-star"></i></span>
                    </td>
                    <td class="action-sec-width">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs"
                        version="1.1"
                        width=""
                        height=""
                        x="0"
                        y="0"
                        viewBox="0 0 52 52"
                        style="enable-background: new 0 0 512 512;"
                        xml:space="preserve"
                        class=""
                      >
                        <g>
                          <g xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26   S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"
                              fill="#56c662"
                              data-original="#000000"
                              style=""
                              class=""
                            />
                            <path
                              d="M38.252,15.336l-15.369,17.29l-9.259-7.407c-0.43-0.345-1.061-0.274-1.405,0.156c-0.345,0.432-0.275,1.061,0.156,1.406   l10,8C22.559,34.928,22.78,35,23,35c0.276,0,0.551-0.114,0.748-0.336l16-18c0.367-0.412,0.33-1.045-0.083-1.411   C39.251,14.885,38.62,14.922,38.252,15.336z"
                              fill="#56c662"
                              data-original="#000000"
                              style=""
                              class=""
                            />
                          </g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                        </g>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs"
                        version="1.1"
                        width=""
                        height=""
                        x="0"
                        y="0"
                        viewBox="0 0 512 512"
                        style="enable-background: new 0 0 512 512;"
                        xml:space="preserve"
                        class="ml-3"
                      >
                        <g>
                          <g xmlns="http://www.w3.org/2000/svg">
                            <g>
                              <path
                                d="M256,0C114.508,0,0,114.497,0,256c0,141.493,114.497,256,256,256c141.492,0,256-114.497,256-256    C512,114.507,397.503,0,256,0z M256,472c-119.384,0-216-96.607-216-216c0-119.385,96.607-216,216-216    c119.384,0,216,96.607,216,216C472,375.385,375.393,472,256,472z"
                                fill="#ff0000"
                                data-original="#000000"
                                style=""
                                class=""
                              />
                            </g>
                          </g>
                          <g xmlns="http://www.w3.org/2000/svg">
                            <g>
                              <path
                                :d="x"
                                fill="#ff0000"
                                data-original="#000000"
                                style=""
                                class=""
                              />
                            </g>
                          </g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                          <g xmlns="http://www.w3.org/2000/svg"></g>
                        </g>
                      </svg>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import KTCodePreview from "@/view/content/CodePreview.vue";

export default {
  data() {
    return {
      x:
        "M343.586,315.302L284.284,256l59.302-59.302c7.81-7.81,7.811-20.473,0.001-28.284c-7.812-7.811-20.475-7.81-28.284,0  " +
        "  L256,227.716l-59.303-59.302c-7.809-7.811-20.474-7.811-28.284,0c-7.81,7.811-7.81,20.474,0.001,28.284L227.716,256    " +
        "l-59.302,59.302c-7.811,7.811-7.812,20.474-0.001,28.284c7.813,7.812,20.476,7.809,28.284,0L256,284.284l59.303,59.302    " +
        "c7.808,7.81,20.473,7.811,28.284,0C351.398,335.775,351.397,323.112,343.586,315.302z"
    };
  },
  computed: {},
  components: {},
  methods: {},
  mounted() {}
};
</script>

<style scoped>
.search-box-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-box-area .form-group {
  flex: 0 0 30%;
  max-width: 30%;
}
.publish-del-section .form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.publish-del-section .btnn {
  min-width: 100px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  border-radius: 40px;
  text-transform: uppercase;
  font-weight: 600;
}
.publish-del-section .success-btn {
  background: #e3f8e5;
  border: 1px solid #56c662;
  color: #56c662;
}
.publish-del-section .success-btn i {
  color: #56c662;
}
.publish-del-section .delete-btn {
  background: #fff3f0;
  border: 1px solid #ff4f4f;
  color: #ff4f4f;
}
.publish-del-section .delete-btn i {
  color: #ff4f4f;
}
.publish-del-section .refresh-btn {
  background-color: #624fd1;
  border-radius: 53px;
  min-width: 35px;
  height: 35px;
}
.publish-del-section .refresh-btn i {
  color: #ffffff;
}
.publish-del-section .calendar-dropdown {
  min-width: 120px;
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  height: 35px;
  line-height: 35px;
  border-radius: 40px;
  padding: 0 15px;
  color: #202020;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-box-area .fa-search {
  position: absolute;
  top: 12px;
  right: 15px;
  color: #fd683e;
}
.search-box-area input {
  padding-right: 40px !important;
}

.reviews-section-wrapper {
  background-color: #ffffff;
  padding: 15px 0;
  border-radius: 20px;
}
.reviews-wrapper table tr {
  border-left: 3px solid transparent;
  padding: 15px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.reviews-wrapper table tr:hover {
  box-shadow: 0 4px 34px rgba(0, 0, 0, 0.1);
  border-left: 3px solid #fd683e;
}
.reviews-wrapper table tr td {
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
}
/*.reviews-wrapper table tr td img {*/
/*  border-radius: 50%;*/
/*}*/
.reviews-wrapper table tr td.img-sec-width {
  width: 7%;
}
.reviews-wrapper table tr td.heading-sec-width {
  width: 21%;
}
.reviews-wrapper table tr td.detail-sec-width {
  width: 50%;
  padding-top: 10px;
}
.reviews-wrapper table tr td.rating-sec-width {
  width: 12%;
}
.reviews-wrapper table tr td.action-sec-width {
  width: 10%;
}
.reviews-wrapper table tr td.img-sec-width img {
  width: 100%;
}
.reviews-wrapper table tr td i {
  font-size: 12px;
}
.reviews-wrapper table tr td svg {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
</style>
